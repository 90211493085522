import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectToSignIn = () => redirectUnauthorizedTo(['sign-in']);
const redirectToSignedInTo = () => redirectLoggedInTo(['']);

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@features/home').then(m => m.HomeModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectToSignIn },
    },
    {
        path: 'sign-in',
        loadChildren: () => import('@core/authentication').then(m => m.SignInModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectToSignedInTo },
    },
    {
        path: 'not-found',
        loadChildren: () => import('@core/pages').then(m => m.NotFoundModule),
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            useHash: true,
            onSameUrlNavigation: 'reload',
        },
    )],
    exports: [RouterModule],
})
export class AppRoutingModule { }

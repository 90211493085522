export const environment = {
    production: true,
    version: '1.0.0',
    firebase: {
        apiKey: 'AIzaSyBK3stTuNNwFdMz7eGnTluXX68Twj-Y1a4',
        authDomain: 'sprint-effort-planning.firebaseapp.com',
        projectId: 'sprint-effort-planning',
        storageBucket: 'sprint-effort-planning.appspot.com',
        messagingSenderId: '704174337275',
        appId: '1:704174337275:web:399fe39d2bdc0c4756545e',
        measurementId: 'G-014T9M79MH',
        region: 'us-east1',
    },
};
